<template>
  <div class="area">
    <el-upload
      class="upload-demo"
      drag
      action=""
      :before-upload="beforeAvatarUpload"
      :http-request="uploadFile"
      :data="param"
      accept="image/*"
      :show-file-list="false"
      multiple
    >
      <i class="el-icon-upload" v-if="isShow"></i>
      <img :src="path" alt="" style="height: 100%" />
    </el-upload>
  </div>
</template>

<script>
  import OSS from "ali-oss";

export default {
  props: {
    title: String,
    param: Object,
    field: String,
    urlPath: String,
  },
  data() {
    return {
      sendAddress: "",
      provenceArray: [],
      actionUploadUrl: "",
      path: "",
      isShow: true,
    };
  },
  methods: {
    async ossUpload(file) {
      let _this = this
      let url = '';
      let filename = file.name;
      let filenames = filename.split('.')[0] + '_' + new Date().getTime() + '.' + filename.split('.')[1];
      let envPath = process.env.VUE_APP_UPLOAD_PATH;
      let filePath = "appxcx/upload/"+(envPath)+"/" + this.param.fileType + "/";
      let path = filePath + filenames;
      try {
        var object = await _this.client.multipartUpload(path, file);
        var img = object.res.requestUrls[0].split("?uploadId");
        url = img[0];
      } catch (e) {
        console.log(e)
      }
      let emit = {
        data: {
          fileName: filenames,
          fileOriName: filename.split('.')[0],
          filePath: filePath
        },
        url: url,
        field: this.field,
      };
      console.log(emit)
      this.$emit("getUploadFile", emit);
      this.isShow = false;
      this.path = emit.url;
      this.$forceUpdate();
    },
    async uploadFile(option) {
      const result = await this.ossUpload(option.file);
      if(!result.url){
        console.log("return oss url null")
        return false;
      }
      this.path = result.url;
      this.isShow = false;
      const data = {
        data: result.data,
        url: result.url,
        field: this.field,
      };
      console.log(data);
    },
    beforeAvatarUpload(file) {
      //文件上传之前调用做一些拦截限制
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB! ");
      }
      return isLt2M;
    },
  },
  created() {
    if (this.urlPath) {
      this.path = this.urlPath ? this.urlPath : '';
      this.isShow = false;
    }
    // console.log(this.param,this.path,this.field)
    this.client = new OSS(this.$config.aliOss);
  },
  watch:{
    urlPath:function() {
      this.path = this.urlPath ? this.urlPath : '';
      this.isShow = false;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
  /deep/
.el-upload-dragger {
  background-color: transparent;
  border: 1px dashed #d9d9d9;
  position: relative;
  overflow: hidden;
  height: 60px;
  width: 60px;
}

.el-upload-dragger .el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 6px;
  padding: 101px 65px 29px 65px;
  min-width: 64%;
}
.el-upload-dragger .el-icon-upload {
  line-height: normal;
  margin: 0px;
  font-size: 48px;
}
.upload-demo,.el-upload-dragger  {
  text-align: center;
}
</style>
